<template>
  <div class="personal-follow">
    <!--头部导航-->
    <!--头部导航-->
    <div class="follow-nav">
      <i class="fas fa-angle-left" @click="btnout"></i>
      <span>我的关注</span>
    </div>

    <div v-for="item in follist" :key="item.id">
      <followuseritem :xh_collect="item.xh_collect" :userlist="item"></followuseritem>
    </div>
  </div>
</template>

<script>
import Followuseritem from './Followuseritem'
import { queryMyfocus } from 'network/follow'
export default {
  name: 'PersonalFollow',
  components: {
    Followuseritem
  },
  data() {
    return {
      follist: [],
      pageIndex: 1,
      totalPages: 1
    }
  },
  created() {
    this.getUserFollow()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    btnout() {
      this.$router.push('/user')
    },
    /*获取关注*/
    getUserFollow() {
      var that = this
      queryMyfocus(1, that.pageIndex).then((res) => {
        that.follist = that.follist.concat(res.data.result.storeList)
        that.totalPages = res.data.result.page.totalPages
        that.pageIndex = res.data.result.page.nowPage + 1
      })
    },
    handleScroll() {
      //这里使用个延时加载，不然滑动屏幕的时候会不断触发方法，去计算高度，浪费性能
      let that = this
      let scrollTop
      clearTimeout(this.timer)
      that.timer = setTimeout(function() {
        scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        //获取到关键属性的值后，判断即可
        if (scrollTop + windowHeight >= scrollHeight) {
          var pageIndex = that.pageIndex
          if (pageIndex <= that.totalPages) {
            queryMyfocus(1, that.pageIndex).then((res) => {
              that.follist = that.follist.concat(res.data.result.storeList)
              that.totalPages = res.data.result.page.totalPages
              that.pageIndex = res.data.result.page.nowPage + 1
            })
          }
        }
      }, 500)
    }
  }
}
</script>

<style scoped>
.personal-follow {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
.follow-nav {
  height: 39px;
  line-height: 39px;
  text-align: center;
  box-shadow: 0 0 5px rgba(50, 50, 50, 0.3);
  display: flex;
  align-items: center;
}
.follow-nav i {
  width: 10%;
  font-size: 20px;
}
.follow-nav span {
  flex: 1;
  margin-right: 8%;
  font-size: 14px;
}
</style>
